import React from 'react';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { styled } from 'styles';
import LazyLoad from 'react-lazyload';

import IndexLayout from 'layouts';
import { Icon, ListingCard, ListingCardSkeleton, Map, MapSkeleton } from 'components';
import SavedListingsHeader from './SavedListingsHeader';
import useSavedListingsLogic from './useSavedListingsPage';

const SavedListingsPage = () => {
  const {
    mapLoaded,
    mapData,
    listingsMarkers,
    loading,
    listings,
    view,
    layout,
    isEmpty,
    onMapChange,
    onMapLoad,
    setView
  } = useSavedListingsLogic();

  const map = (
    <StyledMapContainer className="map-container">
      {!mapLoaded ? <MapSkeleton /> : null}
      <Map
        zoom={mapData?.zoom}
        center={mapData?.center}
        listings={listingsMarkers}
        onChange={onMapChange}
        onLoad={onMapLoad}
      />
    </StyledMapContainer>
  );

  const results = (
    <div className="results" id="lazy-scroll-container">
      <h1>Saved Listings</h1>
      {loading ? null : (
        <div className="results__counter">
          {listings.length} {pluralize('home', listings.length)}
        </div>
      )}
      <div className="results__cards">
        {loading
          ? Array(6)
              .fill(undefined)
              .map((_, idx) => (
                <ListingCardSkeleton
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  style={{ width: '100%', maxWidth: 420, margin: 'auto' }}
                />
              ))
          : listings.map(item => (
              <LazyLoad
                key={item.id}
                height={276}
                scrollContainer={document.querySelector('#lazy-scroll-container') || undefined}
                overflow>
                <ListingCard data={item} />
              </LazyLoad>
            ))}
      </div>
      {isEmpty && !loading && (
        <div className="results__no-results">There are no saved listings yet.</div>
      )}
    </div>
  );

  const getLayout = () => {
    switch (layout) {
      case 'tablet':
      case 'mobile':
        return (
          <>
            <SavedListingsHeader />
            <div className="content">
              {view === 'list' && results}
              {view === 'map' && map}
            </div>

            <StyledTabs className="mobile-tabs">
              <button onClick={() => setView('list')} className={clsx({ active: view === 'list' })}>
                <Icon name="list" />
                List
              </button>
              <button onClick={() => setView('map')} className={clsx({ active: view === 'map' })}>
                <Icon name="pin" />
                Map
              </button>
            </StyledTabs>
          </>
        );
      default:
        return (
          <>
            {map}
            <SavedListingsHeader />
            <div className="content">{results}</div>
          </>
        );
    }
  };

  return (
    <IndexLayout>
      <StyledSavedListings className="saved-listings">{getLayout()}</StyledSavedListings>
    </IndexLayout>
  );
};

export default SavedListingsPage;

const StyledSavedListings = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: grid;
  position: relative;
  overflow: hidden;

  h1 {
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    margin: 4px 0 16px;
  }

  .content {
    grid-area: content;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
  }

  .results {
    display: flex;
    flex-direction: column;
    padding: 0 32px;
    height: 100%;
    overflow: auto;

    &__counter {
      display: flex;
      width: 100%;
      margin-bottom: 16px;
      font-size: 14px;
      line-height: 17px;
    }

    &__cards {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      margin-bottom: 16px;
      width: 100%;

      .property-card {
        margin: 0 auto;
        width: 100%;
      }
    }

    &__no-results {
      font-size: 16px;
      line-height: 24px;
      max-width: 345px;
      text-align: center;
      margin: auto;
    }
  }

  /* desktop */
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-template-areas:
      'map header'
      'map content';
    grid-template-columns: 1fr 720px;
    grid-template-rows: auto 1fr;

    .results {
      padding: 0 32px;
    }
  }

  /* tablet */
  @media (max-width: ${props => props.theme.breakpoints.md}) {
    grid-template-areas:
      'header'
      'content'
      'tabs';
    grid-template-rows: auto 1fr auto;

    h1 {
      margin: 8px 0 24px;
    }

    .results {
      padding: 0 32px;
      margin-bottom: 58px;
    }
  }

  /* mobile */
  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    grid-template-areas:
      'header'
      'content'
      'tabs';
    grid-template-rows: auto 1fr 42px;

    .results {
      padding: 0 10px;
      margin-bottom: 58px;

      h1 {
        margin: 12px 0 20px;
      }

      &__counter {
        margin-bottom: 12px;
      }

      &__cards {
        grid-template-columns: initial;

        .property-card {
          width: 100%;
          max-width: initial;
          margin: 0;
        }
      }
    }
  }
`;

const StyledMapContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  grid-area: map / map / map / map;

  .map {
    width: 100%;
    height: 100%;
  }
`;

const StyledTabs = styled.div`
  position: fixed;
  z-index: 10;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  height: 42px;
  background: #ffffff;

  button {
    width: 50%;
    border: none;
    background: none;
    font-weight: bold;
    font-size: 12px;
    line-height: 120%;
    height: 100%;
    padding: 0;
    outline: none;
    cursor: pointer;

    &.active {
      color: ${props => props.theme.colors.primary};
      fill: ${props => props.theme.colors.primary};
    }

    .icon {
      vertical-align: middle;
      margin-right: 10px;
      width: 24px;
      height: 24px;
    }
  }
`;
