import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';

import { Components } from 'sitedata';
import { styled } from 'styles';
import { Icon, Button, AuthMenu } from 'components';
import { AsideMenu } from 'components/Header';
import { useAuthLink, useLayout } from 'hooks';
import { AuthContext, AuthModalContext } from 'auth';

const SavedListingsHeader = () => {
  const { authorized } = useContext(AuthContext);
  const { setAuthModalOpen } = useContext(AuthModalContext);
  const layout = useLayout();
  const authLink = useAuthLink();
  const [openMenuModal, setOpenMenuModal] = useState(false);

  switch (layout) {
    case 'desktop':
      return (
        <StyledSavedListingsHeader className="header">
          <Components.Logo compact to="/" />
          <Link className="header__link" to={authLink || '/saved-listings'}>
            <Icon name="heart" /> Saved Listings
          </Link>
          <Link className="header__link" to={authLink || '/saved-searches'}>
            <Icon name="search" /> Saved Searches
          </Link>
          {authorized ? (
            <AuthMenu className="auth" />
          ) : (
            <Button link onClick={() => setAuthModalOpen(true)} className="auth">
              Sign in
            </Button>
          )}
        </StyledSavedListingsHeader>
      );
    default:
      return (
        <StyledSavedListingsHeader className="header">
          <Components.Logo to="/" />
          <Button simple className="menu-button" onClick={() => setOpenMenuModal(true)}>
            <Icon name="burger-menu" />
          </Button>
          <AsideMenu open={openMenuModal} onClose={() => setOpenMenuModal(false)} />
        </StyledSavedListingsHeader>
      );
  }
};

export default SavedListingsHeader;

const StyledSavedListingsHeader = styled.header`
  grid-area: header;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.borderColor};
  margin: 0 32px 16px 32px;

  & > a,
  & > button {
    font-size: 14px;
    line-height: 24px;
    color: #000;
    text-decoration: none;
    margin-left: 16px;
    cursor: pointer;
    outline: none;
    padding: 0;

    .icon-burger-menu {
      fill: ${props => props.theme.colors.black};
      vertical-align: middle;
    }
    &.logo__link {
      margin: 0;
    }
  }

  .header__link {
    display: flex;
    align-items: center;
    position: relative;

    &:first-of-type:after {
      content: '';
      position: absolute;
      width: 1px;
      background: ${props => props.theme.colors.harborGray};
      height: 16px;
      top: 50%;
      transform: translateY(-50%);
      right: -9px;
    }

    .icon {
      margin-right: 4px;
      fill: ${props => props.theme.colors.primary};
    }
  }

  .auth {
    margin-left: 32px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    grid-area: header;
  }

  @media (max-width: ${props => props.theme.breakpoints.md}) {
    height: 56px;
    margin: 0 10px 16px 10px;
    border-bottom: none;
  }

  @media (max-width: ${props => props.theme.breakpoints.sm}) {
    padding: 0 10px;
    height: 56px;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px 0;
    border-bottom: 1px solid ${props => props.theme.colors.borderColor};

    .logo {
      margin: 0 24px 0 0;
    }

    .menu-button {
      margin-left: 24px;
      width: 32px;
      height: 32px;
    }
  }
`;
