import { useState, useEffect } from 'react';

import { Coords, Bounds } from 'google-map-react';
import { getSavedListings } from 'api/listings';
import useLayout from 'hooks/useLayout';
import notify from 'notify';
import { useAuthContext } from 'hooks';

type ViewType = 'list' | 'map';

const useSavedListingsPage = () => {
  const layout = useLayout();
  const { savedItems, setSavedItems } = useAuthContext();
  const [listings, setListings] = useState<ListingCardData[]>([]);
  const [mapLoaded, setMapLoaded] = useState<boolean>(false);
  const [mapData, setMapData] = useState<{ center: Coords; bounds: Bounds; zoom: number }>();
  const [listingsMarkers, setListingsMarkers] = useState<ListingsSearchMarker[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [view, setView] = useState<ViewType>('list');
  const isEmpty = listings.length === 0;

  const loadListings = async () => {
    setLoading(true);
    await getListings();
    setLoading(false);
  };

  const getListings = async () => {
    try {
      const { listings, markers } = await getSavedListings();
      setSavedItems({
        savedSearches: savedItems?.savedSearches || [],
        savedListings: listings.map(item => item.id)
      });
      setListings(listings);
      if (markers) setListingsMarkers(markers);
    } catch (err) {
      setListings([]);
      setListingsMarkers([]);
      notify(err.message);
    }
  };

  useEffect(() => {
    loadListings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    mapLoaded,
    mapData,
    listingsMarkers,
    loading,
    listings,
    view,
    layout,
    isEmpty,
    setView,
    onMapChange: ({ center, bounds, zoom }) => setMapData({ center, bounds, zoom }),
    onMapLoad: () => setMapLoaded(true)
  };
};

export default useSavedListingsPage;
