import React from 'react';

import PrivateRoute from 'components/PrivateRoute';
import SavedListingsPage from 'views/SavedListingsPage';

export default () => (
  <PrivateRoute>
    <SavedListingsPage />
  </PrivateRoute>
);
